import { useEffect, useState } from "react";
import isPageWholesale from "utils/getSubdomain";
import { api, handlerError } from "config/axiosConfig";

const monedas: any = {
  USD: 3500,
  EUR: 4000,
};

function useProducts({ currency }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [category, setCategory] = useState("");
  const isWholesale = isPageWholesale();

  const mapingChangeProducts = (list: any, currency: any) => {
    let newList = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const newPrice =
        currency === "COP"
          ? element.price
          : Number(element.price / monedas[currency]).toFixed(2);
      newList.push({ ...element, price: newPrice });
    }
    return newList;
  };

  useEffect(() => {
    const mapProducts: any = mapingChangeProducts(allProducts, currency);
    if (category === "") {
      setProducts(mapProducts);
    } else {
      const filterProducts: any = [...mapProducts]
        .filter((p: any) => p.category._id === category)
        .sort((a: any, b: any) => a.price - b.price);
      setProducts(filterProducts);
    }
    // eslint-disable-next-line
  }, [category, currency, allProducts]);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        let { data }: any = await api.get(`/products`);
        data = data
          .map((p: any) => ({
            ...p,
            images: p.images.map((img: any) => img.fileUrl),
            price: p[isWholesale ? "priceWholesale" : "price"],
          }))
          .sort((a: any, b: any) => a.price - b.price);
        setProducts(data);
        setAllProducts(data);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        handlerError(error);
      }
    };

    if (!isCancelled) fetchData();

    return () => {
      isCancelled = true;
    };
  }, [isWholesale]);

  return {
    isLoading,
    products,
    category,
    setCategory,
  };
}

export default useProducts;
