import { useEffect, useState } from "react";
import { api, handlerError } from "config/axiosConfig";

function useCategories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        let { data }: any = await api.get("/categories");
        data = data.map((p: any) => ({
          ...p,
          image: p.image.fileUrl,
        }));
        setCategories(data);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        handlerError(error);
      }
    };

    if (!isCancelled) fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return {
    isLoading,
    categories,
  };
}

export default useCategories;
