import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { ProductCart } from "data/data";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactInfo from "./ContactInfo";
import ShippingAddress from "./ShippingAddress";
import useCart from "hooks/useCart";
import { formatCurrency } from "utils/formatCurrency";
import { SubmitHandler, useForm } from "react-hook-form";
import useOrder from "hooks/useOrder";
import Spinner from "shared/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DiscountForm from "./DiscountForm";
import isPageWholesale from "utils/getSubdomain";

type OrderInputs = {
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    notes: string;
  }
}

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { cart, total, discount, removeCart, increaseCartQuantity, decreaseCartQuantity, clearCart } = useCart()
  const { confirmOrder, isLoading } = useOrder()
  const { register, handleSubmit, formState: { errors } } = useForm<OrderInputs>()
  const isWholesale = isPageWholesale();

  const onSubmit: SubmitHandler<OrderInputs> = async (data) => {
    try {
      await confirmOrder({
        ...data,
        "customerAcceptSubscription": true,
        "useSameAddressToBilling": true,
        productsOrder: cart.map(c => ({
          productId: c._id,
          productName: c.name,
          productImagen: c.images[0],
          productSku: c.sku,
          quantity: c.quantity,
          price: c.price
        }))
      })
      clearCart()
      navigate('/order-confirm')
    } catch (error: any) {
      console.error(error);
      toast.error(error.message, { position: "top-right"})
    }
  }

  const renderProduct = (item: ProductCart, index: number) => {
    const { _id, images, price, name, sku, quantity, stock } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={images[0]}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <span>{sku}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <NcInputNumber className="" defaultValue={quantity} max={stock} decreaseQuantity={() => decreaseCartQuantity(_id)} increaseQuantity={() => increaseCartQuantity(_id)}/>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" defaultValue={quantity} max={stock} decreaseQuantity={() => decreaseCartQuantity(_id)} increaseQuantity={() => increaseCartQuantity(_id)}/>
            </div>

            <button
              type="button"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              onClick={() => removeCart(item)}
            >
              <span>Eliminar</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={true}
            onOpenActive={() => {}}
            onCloseActive={() => {}}
            register={register}
            errors={errors}
          />
        </div>

        <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            isActive={true}
            onOpenActive={() => {}}
            onCloseActive={() => {}}
            register={register}
            errors={errors}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Checkout || Tanylu</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Checkout
          </h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col lg:flex-row">
            <div className="flex-1">
              {renderLeft()}
            </div>

            <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

            <div className="w-full lg:w-[36%] ">
              <h3 className="text-lg font-semibold">Resumen del pedido</h3>
              <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                {cart.map(renderProduct)}
              </div>

              <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">         
                {!isWholesale && <DiscountForm />}
                
                <div className="mt-4 flex justify-between py-2.5">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {formatCurrency(total)}
                  </span>
                </div>
                <div className="flex justify-between py-2.5">
                  <span>Envio</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {formatCurrency(0)}
                  </span>
                </div>
                {discount > 0 && (
                  <div className="flex justify-between py-2.5">
                    <span>Descuento</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {formatCurrency(discount)}
                    </span>
                  </div>
                )}
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Total del pedido</span>
                  <span>{discount > 0 ? formatCurrency(total - discount) : formatCurrency(total)}</span>
                </div>
              </div>
              <ButtonPrimary type="submit" className="mt-8 w-full">
                {isLoading ? <Spinner size="fa-1x" /> : 'Confirmar pedido'}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CheckoutPage;
