import React, { FC, useRef, useState } from 'react'
import Label from 'components/Label/Label'
import Input from 'shared/Input/Input'
import Spinner from 'shared/Spinner/Spinner';
import toast from 'react-hot-toast';
import { api, handlerError } from 'config/axiosConfig';
import useCart from 'hooks/useCart';

const DiscountForm: FC<any> = () => {  
  const [isLoading, setIsLoading] = useState(false)
  const codeInputRef: any = useRef();
  const { addCoupon } = useCart()

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true)
    try {
      const code = codeInputRef.current.value;  
      const { data }: any = await api.post('/redeem-coupon', { code })      
      addCoupon(data)
      toast.success('Cupón redimido con éxito!!', { position: "top-right", duration: 3000 }) 
      setIsLoading(false)
      codeInputRef.current.value = "";
    } catch (error) {
      toast.error("No se puede redimir el cupón está inhabilitado o no existe", { position: "top-right", duration: 3000 })
      setIsLoading(false)
      handlerError(error);
    }
  }
    
  return (
      <div>
        <Label className="text-sm">Código de descuento</Label>
        <div className="flex mt-1.5">
          <div className="flex-1">
            <Input type='text' name='code' ref={codeInputRef} />
          </div>
          <button type='button' onClick={handleSubmit} className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
            {isLoading ? <Spinner size="fa-1x" /> : 'Aplicar'}
          </button>
        </div>
      </div>
  )
}

export default DiscountForm