import { useState } from "react";
import { api, handlerError } from "config/axiosConfig";

function useOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();

  const confirmOrder = async (newOrder: any) => {
    setIsLoading(true);
    try {
      let { data }: any = await api.post("/profile/customer/orders", newOrder);
      setOrder(data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handlerError(error);
    }
  };

  return {
    isLoading,
    order,
    confirmOrder,
  };
}

export default useOrder;
