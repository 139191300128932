import React, { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

interface Props {
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
  register: any;
  errors: any;
}

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  register, 
  errors
}) => {
  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">DIRECCIÓN DE ENVÍO</span>
            </h3>
          </div>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 block`}
        >
          {/* ============ */}
          <Input 
            label="Dirección"
            {...register("address.line1", { required: true })} 
            errors={[
              ...errors?.address?.line1?.type === 'required' ? ['Este campo es requerido'] : []
            ]} />
          <Input 
            label="Casa, apartamento, condominio, etc. (optional)"
            {...register("address.line2")} />

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <Input 
              label="Ciudad" 
              {...register("address.city", { required: true })}
              errors={[
                ...errors?.address?.city?.type === 'required' ? ['Este campo es requerido'] : []
              ]} />
            <Input 
              label="Pais" 
              defaultValue="Colombia" 
              // value="Colombia" 
              // disabled={true}
              {...register("address.country", { required: true })} 
              errors={[
                ...errors?.address?.country?.type === 'required' ? ['Este campo es requerido'] : []
              ]} />
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <Input 
              label="Departamento" 
              {...register("address.state", { required: true })} 
              errors={[
                ...errors?.address?.state?.type === 'required' ? ['Este campo es requerido'] : []
              ]} />
            <Input 
              label="Codigo postal (opcional)"
              {...register("address.postalCode")} />
          </div>

          {/* ============ */}
          <Textarea label="Información adicional (opcional)" {...register("address.notes")} />
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
