import axios from "axios";
const url = process.env.REACT_APP_API_URL || "dev";

export const api = axios.create({
  baseURL: url,
  //withCredentials: true
});

export const handlerError = (error: any) => {
  if (axios.isAxiosError(error)) {
    console.log("isAxiosError :>> ", error);
    throw new Error(error.response?.data?.message, { cause: "axios" });
  } else {
    console.log("otherError :>> ", error);
    throw new Error(error.message, { cause: "function" });
  }
};
